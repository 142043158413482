// 登录
<template>
  <div class="login">
    <div class="title_box">
      <img src="../../assets/images/logo.png" alt="">
      <h1>共创云后台管理系统</h1>
    </div>
    <div class="login_content">
      <div class="login_form">
        <h1>服务平台后台登录</h1>
        <el-form
          class="form"
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules">
          <el-form-item prop="mobile">
            <el-input
              type="text"
              :maxlength="11"
              v-model.trim="loginForm.mobile"
              placeholder="手机号">
            </el-input>
          </el-form-item>
          <el-form-item prop="password" @keyup.native.enter="handleLogin">
            <el-input
              type="password"
              show-password
              v-model.trim="loginForm.password"
              placeholder="密码">
            </el-input>
          </el-form-item>
        </el-form>
        <el-button style="width:100%;margin-top:18px" type="primary" :loading="btnLoading" @click="handleLogin">
          登 录
        </el-button>
      </div>
    </div>
    <!-- 底部的公司名称 -->
    <div class="footer_company">
      <p>优聘科技Copyright © 2015 - 2020 YOYOHR.COM All Rights Reserved</p>
    </div>
  </div>
</template>
<script>
import * as api from '@/api'
import SHA256 from 'crypto-js/sha256'
import cookie from '@/configs/cookie'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      btnLoading: false, // 登陆的button  loading
      // 登录信息
      loginForm: {
        mobile: '', // 目前为手机号
        password: '' // 密码
      },
      // 登录表单验证规则
      loginRules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.openAlert()
  },
  methods: {
    // 打开停机公告
    openAlert () {
      this.$alert('本网站于今天17:30开始进行服务器升级，停机两小时，期间无法访问系统进行操作！对此造成的不便之处，敬请谅解！', '网站维护停机公告', {
        confirmButtonText: '确定',
        callback: (action) => {
          console.log(action)
        }
      })
    },
    // 进行登陆
    handleLogin () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          api.login({
            mobile: this.loginForm.mobile,
            password: SHA256(this.loginForm.password).toString()
          }).then(res => {
            this.btnLoading = false
            if (res.data.code === 0) {
              cookie.set(this.globalVar.CMS_TOKEN, res.data.data.token)
              this.$store.commit('setUserInfo', res.data.data.user)

              const { role } = res.data.data.user
              // 客服
              if (role === globalVar.ADMIN_ROLE_2) {
                this.$router.push('/account-manage')
              // 管理员
              } else {
                this.$router.push('/')
              }
            } else {
              this.$message.error(res.data.message)
            }
          }).catch((error) => {
            this.btnLoading = false
            console.log(error)
            this.$message.error('请求出错')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    position: relative;
    min-width: 1100px;
    width: 100%;
    height: 100%;
    background-image:url('../../assets/images/login/login_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    overflow: hidden;
    .title_box {
      position: absolute;
      top: 65px;
      left: 76px;
      display: flex;
      align-items: center;
      height: 50px;
      & > img {
        width: 40px;
        height: 40px;
      }
      & > h1 {
        margin-left: 10px;
        font-size: 22px;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
    .login_content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1100px;
      height: 640px;
      padding-left: 550px;
      box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.23);
      border-radius: 10px;
      background:url('../../assets/images/login/login_box_left_bg.png') left/550px 640px no-repeat,
                  url('../../assets/images/login/login_box_right_bottom.png') right bottom/241px 127px no-repeat;
      background-color: #ffffff;
      overflow: hidden;
      .login_form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px 95px;
        & > h1 {
          font-size: 26px;
          font-weight: bold;
          line-height: 60px;
          color: #303133;
          text-align: center;
          margin-bottom: 70px;
        }
        .form {
          width: 100%;
          .form_btn {
            width: 100%;
          }
        }
      }
      .login_box_bg {
        display: block;
        width: 540px;
        height: 700px;
        border-radius:10px 0px 0px 10px;
      }
      .login_box_angle {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 241px;
        height: 127px;
      }
    }
    .footer_company {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      &::before {
        content: "";
        width: 238px;
        height: 1px;
        margin-bottom: 12px;
        background-color: rgba(255, 255, 255, 0.5);
      }
      & > p {
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
</style>
